/**
 * Created by tarun on 18/7/17.
 */
import { Injectable } from '@angular/core';

@Injectable()
export class Profile {
  id: string;
  first_name: string;
  last_name: string;
  address: string;
  city: string;
  state: string;
  phone: string;
  email: string;
  location: {coordinates: number[] };
  admin: number;
  company: number;
  email_verified: number;
  profile_pic?: string;
  created: Date;
  exp_months?: number;
  current_city?: string;
  current_state?: string
  current_country?: string
  notice_days?: number
  immediately_available?: string
  age?: number
  gender?: string
  primary_resume_id?: string
  job_role?: string
  current_ctc?: number
  expected_ctc?: number
  company_id?: string
  pro?: number
  paid?: number
  payment_end_time?: Date
  payment_id?: string
  payment_time?: Date
  payment_type?: string


}
