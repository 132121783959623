import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';

function loadScript(src): Observable<any> {
  return new Observable<any>((observer: Observer<any>) => {
    // Load the script
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = src;
    scriptElement.onload = () => {
      observer.next(true);
      observer.complete();
    };
    scriptElement.onerror = (error: any) => {
      observer.error('Couldnt load script ' + src);
    };
    document.getElementsByTagName('body')[0].appendChild(scriptElement);
  });
}

// @Injectable()
// export class CKEDITORResolverService implements Resolve<any> {
//   constructor() { }
//   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
//     return loadScript('https://cdn.ckeditor.com/4.7.3/basic/ckeditor.js');
//   }
// }

@Injectable()
export class EDITORResolverService implements Resolve<any> {
  constructor() { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return loadScript('https://cdn.tiny.cloud/1/3k8plxy5y8k74o0pfjlcznnf5hkr0i9nh8k0pleruab55v62/tinymce/5/tinymce.min.js');
  }
}
/*@Injectable()
export class JobResolverService implements Resolve<any>  {
  constructor(
    private jobService: JobService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const slug = route.paramMap.get('slug');
    return this.jobService.getDetail(slug);
  }
}*/

/*@Injectable()
export class CompaniesResolverService implements Resolve<Array<any>>  {
  constructor(
    private companyService: CompanyService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.companyService.get();
  }
}*/
