import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import { EDITORResolverService } from './core/services/resolvers.service';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login',  loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'themes',  loadChildren: () => import('./theme/theme.module').then(m => m.ThemeModule), resolve: [EDITORResolverService]},
  { path: 'covers',  loadChildren: () => import('./cover/cover.module').then(m => m.CoverModule)},
  { path: 'resume',  loadChildren: () => import('./resume/resume.module').then(m => m.ResumeModule),
   resolve: { CKEDITOR: EDITORResolverService } },
  { path: 'profile',  loadChildren: () => import('.//profile/profile.module').then(m => m.ProfileModule)},
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'job',  loadChildren: () => import('./job/job.module').then(m => m.JobModule) },
  { path: 'company',  loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)},
  { path: 'recruiter',  loadChildren: () => import('./employer/employer.module').then(m => m.EmployerModule)},
  { path: 'resume-sample',  loadChildren: () => import('./resume-samples/resume-samples.module').then(m => m.ResumeSamplesModule)},
  { path: '**',  redirectTo: '',  pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
