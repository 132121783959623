import {NgModule, Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';

import { Config } from '../config/config.config';
import { Token } from '../models/token.model';
import { Session } from '../models/session.model';
import { Profile } from '../models/profile.model';
import { MyInterceptor } from '../interceptors/MyInterceptor';
import { AuthService } from '../core/services/auth.service';
import { LoginGuard } from '../core/services/loginguard.service';
import { EDITORResolverService } from '../core/services/resolvers.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    LocalStorageModule.forRoot({
      prefix: 'ls',
      storageType: 'localStorage'
    }),
  ],
  declarations: [
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true
    },
    Config,
    AuthService,
    LoginGuard,
    Token,
    Session,
    Profile,
    EDITORResolverService
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    LocalStorageModule
  ]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
