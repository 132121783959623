import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { AuthService } from '../core/services/auth.service';
import {Session} from '../models/session.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public pageUrl: any;
  constructor(
    public router: Router,
    private authService: AuthService
  ) {
    router.events.subscribe( e => {
      if (e instanceof NavigationStart) {
        this.pageUrl = e;
        this.authService.fetchToken().then(token => {
          if (token === null) {
            console.log('user logged out');
            return;
          } else {
            /*if (new Date(token.expire).getTime() > new Date().getTime()) {
              console.log('active user restart the session');
              this.authService.getSession().then((session) => {});
            } else {
              console.log('refresh token');
              this.authService.logout().then(data => {
                this.router.navigate(['/']);
              });
            }*/
            this.authService.getSession().then((session) => {});
          }
        });
      }
    });
  }
}
