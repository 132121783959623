import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'angular-2-local-storage';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent {
  title = 'Resume - Homepage';
  constructor(
    private authService: AuthService,
    private router: Router,
    public localStorageService: LocalStorageService,
    config: NgbCarouselConfig,
  ) {
    config.interval = 15000;
    config.wrap = true;
    config.keyboard = false;
    if (this.authService.session.isActive) {
      if (this.authService.session.profile.admin === 1 || this.authService.session.profile.company === 1) {
        this.router.navigate(['/recruiter']);
      } else {
        this.router.navigate(['/profile']);
      }
    }
  }
}
