<div class="headerbg">
  <nav class="navbar">
    <div class="container">
      <div class="row">
        <div class="col-sm-2 col-12 text-center text-sm-right">
          <a routerLink="/">
            <img src="../../../assets/images/logo.png" class="logo" />
          </a>
        </div>
        <div class="col-sm-10 col-12">
          <div class="nav navbar text-center text-sm-right">
            <ul class="list-inline">
              <li class="list-inline-item"><a class="btn" routerLink="/login/register" [queryParams]="{r:'jobseeker', n: 'cover'}">Cover Letter</a></li>
              <li class="list-inline-item"><a class="btn" routerLink="/job"><span class="hidden-xs">Find </span>Jobs</a></li>
              <li class="list-inline-item"><a class="btn btn-outline-info" routerLink="/login">Login</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="headerbg__slideshow">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-sm-8">
          <h1>Easiest Resume Builder</h1>
          <p class="lead">Download a professional job-ready Resume in 5 minutes</p>
          <a routerLink="/login/choose" class="create__button btn btn-lg btn-info ">Create A Resume</a>
        </div>
        <div class="col-sm-10">
          <img src="../../../assets/images/resume-template-free.jpg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="home__clients">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-sm-2 col-4">
        <img src="../../../assets/images/partners/paytm.png" class="img-fluid" />
      </div>
      <div class="col-sm-2 col-4">
        <img src="../../../assets/images/partners/flipkart.png" class="img-fluid" />
      </div>
      <div class="col-sm-2 col-4">
        <img src="../../../assets/images/partners/swiggy.png" class="img-fluid" />
      </div>
      <div class="col-sm-2 col-4">
        <img src="../../../assets/images/partners/ola.png" class="img-fluid" />
      </div>
      <div class="col-sm-2 col-4">
        <img src="../../../assets/images/partners/uber.png" class="img-fluid" />
      </div>
      <div class="col-sm-12 text-center">
        <p class="text-muted">Our resume builder is used by thousands of employees at some of the best companies around the world.</p>
      </div>
    </div>
  </div>
</div>
<div class="home__about">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-6 text-center">
        <img src="../../../assets/images/home_about.png" class="img-fluid" />
      </div>
      <div class="col-sm-6">
        <h2>Sample Resume Lines</h2>
        <p class="text-muted">Get inspired by real resume lines that helped candidates get into companies like amazon, flipkart, paytm and Uber. Our AI enabled Resume builder keep curating the resume lines library so that you only get the best resume line</p>
        <a routerLink="/login/choose" class="create__button">Chose Resume Format >></a>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-12"><p>&nbsp;</p></div>
        <div class="col-sm-6">
            <h2>Change Font</h2>
            <p class="text-muted">Create a unique resume by changing the font on the go. Font customization makes our free resume builder even more special.</p>
            <a routerLink="/login/choose" class="create__button">Try Free Resume Builder Now >></a>
          </div>
      <div class="col-sm-6 text-center">
        <img src="../../../assets/images/resume-font-3.png" class="img-fluid" />
      </div>
    </div>
  </div>
</div>
<!-- <div class="home__about2">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <h2>Sample Resume Lines</h2>
        <p class="text-muted">Get inspired by real resume lines that helped candidates get into companies like amazon, flipkart, paytm and Uber. Our AI enabled Resume builder keep curating the resume lines library so that you only get the best resume line</p>
        <a routerLink="/login/choose" class="create__button">Try Free Resume Builder Now!</a>
      </div>
      <div class="col-sm-6 text-center">
        <img src="../../../assets/images/home_about.png" class="img-fluid" />
      </div>
    </div>
  </div>
</div> -->
<div class="home__feature">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="home__about--blocks">
          <span class="icon icon-cloud"></span>
          <h4>Data Protection</h4>
          <p class="text-muted">All of your personal information and saved resumes are protected with high online security measures and encrypted.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="home__about--blocks">
          <span class="icon icon-shortlist"></span>
          <h4>Live Preview Mode</h4>
          <p class="text-muted">You can see how your complete resume looks as you fill in the content sections, giving you the full picture all the time.</p>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="home__about--blocks">
          <span class="icon icon-resume"></span>
          <h4>Best Resume Format</h4>
          <p class="text-muted">We work with expert HR and design resume formats & resume templates that is most relevant. Use the free resume builder to create a modern resume that stand out.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="home__testimonial">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-sm-12">
        <ngb-carousel>
          <ng-template ngbSlide>
            <div class="carousel-caption">
              <div class="row justify-content-md-center">
                <div class="col-sm-2">
                  <div class="float-right">
                    <img src="../../../assets/images/ambuj.jpg" class="rounded-circle img-fluid" />
                  </div>
                </div>
                <div class="col-sm-9 text-left">
                  <h3>“I was pleasantly surprised to see how MyResumeFormat took the massive burden of me designing and curating my resume. Huge shoutout to the team.”</h3>
                  <h5>Ambuj Mishra</h5>
                  <p>Flipkart</p>
                </div>
              </div>
            </div>
            <img src="../../../assets/images/slideshow__testimonial.png"/>
          </ng-template>
          <ng-template ngbSlide>
            <div class="carousel-caption">
              <div class="row justify-content-md-center">
                <div class="col-sm-2">
                  <div class="float-right">
                    <img src="../../../assets/images/himanshu.jpg" class="rounded-circle img-fluid" />
                  </div>
                </div>
                <div class="col-sm-9 text-left">
                  <h3>“MyResumeFormat has been a massive plus during my job search — The HR of the company I applied for complimented on how clean and precise my resume was, she really love it.”</h3>
                  <h5>Himanshu Mantri</h5>
                  <p>Paytm</p>
                </div>
              </div>
            </div>
            <img src="../../../assets/images/slideshow__testimonial.png"/>
          </ng-template>
          <ng-template ngbSlide>
              <div class="carousel-caption">
                <div class="row justify-content-md-center">
                  <div class="col-sm-2">
                    <div class="float-right">
                      <img src="../../../assets/images/Archit.jpeg" class="rounded-circle img-fluid" />
                    </div>
                  </div>
                  <div class="col-sm-9 text-left">
                    <h3>“Great UI/UX. The best part of the product is that it auto-suggests resume lines based on your industry/job role. Easy to use, has multiple templates, best of all FREE!”</h3>
                    <h5>Archit Rathi</h5>
                    <p>London Business School</p>
                  </div>
                </div>
              </div>
              <img src="../../../assets/images/slideshow__testimonial.png"/>
            </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</div>
<div class="home__featured">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 text-center text-sm-left">
        <h2>
          Media Coverage
        </h2>
      </div>
      <div class="col-sm-6 text-center text-sm-right">
        <a routerLink="/login/choose" class="create__button btn btn-lg btn-outline">Try Free Resume Builder Now!</a>
      </div>
      <div class="col-sm-4">
        <div class="home__featured--box left">

          <div class="row align-items-center">
            <div class="col-8">
                <h3>
                    Featured as product of the day. Best Resume builder.
                  </h3>
              <p><a href="https://www.producthunt.com/posts/my-resume-format" target="_blank">Product Hunt</a></p>
            </div>
            <div class="col-4 text-right">
              <div class="home__featured--logo">
                <img src="../../../assets/images/ph-logo.png" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="home__featured--box center">
          <div class="row align-items-center">
            <div class="col-8">
                <h3>
                    Featured as best Free Resume Builder online.
                  </h3>
              <p><a href="https://tamil.thehindu.com/general/technology/article25598113.ece" target="_blank">The Hindu</a></p>
            </div>
            <div class="col-4 text-right">
              <div class="home__featured--logo">
                <img src="../../../assets/images/thehindu-logo.png" class="img-fluid">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
          <div class="home__featured--box right">
            <div class="row align-items-center">
              <div class="col-8">
                  <h3>
                      Best resources for creating a resumé for free.
                    </h3>
                <p><a href="https://en.softonic.com/solutions/websites/my-resume-format" target="_blank">Softonic</a></p>
              </div>
              <div class="col-4 text-right">
                <div class="home__featured--logo">
                  <img src="../../../assets/images/softtonic-logo.png" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
<footer id="colophon" class="site-footer" role="contentinfo">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        © 2019 <a href="https://myresumeformat.com">My Resume Format - Free Resume Builder</a>				</div>
      <div class="col-sm-8">
        <div id="footer-widget" class="row">
          <div class="col-12 col-md-4"><section id="nav_menu-2" class="widget widget_nav_menu"><div class="menu-footer-1-container"><ul id="menu-footer-1" class="menu nav flex-column"><li id="menu-item-1027" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1027 nav-item"><a href="https://www.myresumeformat.com/resume-builder/" class="nav-link">Resume Builder</a></li>
            <li id="menu-item-581" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-581 nav-item"><a href="https://www.myresumeformat.com/resume-format/" class="nav-link">Resume Format</a></li>
          </ul></div></section></div>
          <div class="col-12 col-md-4"><section id="nav_menu-3" class="widget widget_nav_menu"><div class="menu-footer-2-container"><ul id="menu-footer-2" class="menu nav flex-column"><li id="menu-item-1033" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-1033 nav-item"><a href="https://www.myresumeformat.com/resume-sample/civil-engineering-resume-examples-correct-resume-format/" class="nav-link">Civil Engineering Resume</a></li>
            <li id="menu-item-1034" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-1034 nav-item"><a href="https://www.myresumeformat.com/resume-sample/medical-assistant-resume-sample/" class="nav-link">Medical Assistant Resume</a></li>
            <li id="menu-item-1035" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-1035 nav-item"><a href="https://www.myresumeformat.com/resume-sample/nurse-resume-sample-latest-resume-example/" class="nav-link">Nurse Resume</a></li>
            <li id="menu-item-1036" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1036 nav-item"><a href="https://www.myresumeformat.com/resume-example/" class="nav-link">Resume Example</a></li>
            <li id="menu-item-1037" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1037 nav-item"><a href="https://www.myresumeformat.com/resume-sample/" class="nav-link">Resume Sample</a></li>
          </ul></div></section></div>
          <div class="col-12 col-md-4"><section id="nav_menu-4" class="widget widget_nav_menu"><div class="menu-footer-3-container"><ul id="menu-footer-3" class="menu nav flex-column"><li id="menu-item-1038" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-1038 nav-item"><a href="https://www.myresumeformat.com/blog/" class="nav-link">Blog</a></li>
            <li id="menu-item-1039" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1039 nav-item"><a href="https://www.myresumeformat.com/faq/" class="nav-link">FAQ</a></li>
            <li id="menu-item-1040" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1039 nav-item"><a href="https://www.myresumeformat.com/about/" class="nav-link">About Us</a></li>
            <li id="menu-item-1041" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1040 nav-item"><a href="https://www.myresumeformat.com/contact-us/" class="nav-link">Contact Us</a></li>
            <li id="menu-item-1042" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1041 nav-item"><a href="https://www.myresumeformat.com/terms-conditions/" class="nav-link">Terms &amp; Conditions</a></li>
          </ul></div></section></div>
        </div>
      </div>
    </div>
  </div>
</footer>

