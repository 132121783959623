/**
 * Created by tarun on 21/7/17.
 */
import { Injectable } from '@angular/core';

@Injectable()
export class Config {
  public apiBase = 'https://api.myresumeformat.com/apis/';
  public apiV = 'v1';
}
