/**
 * Created by tarun on 16/7/17.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import { LocalStorageService } from 'angular-2-local-storage';

import { Token } from '../../models/token.model';
import { Session } from '../../models/session.model';
import { Profile } from '../../models/profile.model';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    public token: Token,
    public session: Session
  ) {
    this.token = new Token();
    const profile = this.localStorageService.get('profile');
    const l_token = this.localStorageService.get('token');
    if (l_token && profile) {
      this.token = l_token as Token;
      this.session.token = this.token;
      this.session.isActive = true;
      this.session.profile = profile as Profile;
    } else {
      this.session.isActive = false;
    }
    // console.log(this.session.isActive);
  }
  isSubscriptionValid(): boolean {
    if (this.session.isActive) {
      if (this.session.profile.pro === 1) {
        return true;
      }
      if (this.session.profile.paid === 1) {
        if (this.session.profile.payment_type === 'LIFETIME') {
          return true;
        }
        const now = new Date();
        const valid_till = new Date(this.session.profile.payment_end_time);
        console.log(now , valid_till);
        if (valid_till >= now) {
          return true;
        }
      }
    }
    return false;
  }
  signup(user): Observable<any> {
    return this.http.post(
      `/register`,
      user,
      {}
    );
  };

  login(user): Observable<any> {
    return this.http.post(
      `/login`,
      user,
      {}
    );
  };

  saveToken(token: Token): boolean {
    return this.localStorageService.set('token', token);
  };
  saveProfile(profile: any): boolean {
    return this.localStorageService.set('profile', profile);
  }
  getToken(): Token {
    return this.localStorageService.get('token') as Token ;
  }
  getProfile(): Profile {
    return this.localStorageService.get('profile') as Profile;
  }
  fetchToken(): Promise<any> {
    return Promise.resolve(this.localStorageService.get('token'));
  }

  refreshToken(): void {

  };

  getUserByToken(token): Observable<any> {
    return this.http.get(
      `/user/profile`,
      {}
    );
  };
  startSession(token: Token): Promise<Session> {
    return new Promise((resolve, reject) => {
      this.saveToken(token);
      this.token = token;
      this.session.token = token;
      this.session.isActive = true;
      this.getUserByToken(this.token.token).subscribe((res) => {
        this.session.profile = res.user;
        this.saveProfile(this.session.profile);
        console.log('session in start', this.session);
        resolve(this.session);
      });
    });
  };
  getSession(): Promise<Session> {
    return new Promise((resolve, reject) => {
      this.session.token = this.getToken();
      this.session.profile = this.getProfile();
      if (this.session.token && this.session.profile) {
        this.session.isActive = true;
      } else {
        this.session.isActive = false;
      }
      resolve(this.session);
    });
  };
  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.session = new Session();
      this.token = new Token();
      this.localStorageService.remove('token');
      this.localStorageService.remove('profile');
      resolve(true);
    });
  }
  generateResume(data: any, id): Observable<any> {
    const header: HttpHeaders = new HttpHeaders({'Content-Type': 'text/html'});
    return this.http.post(
      `/resume/pdf/${id}`,
      data,
      {}
    );
  }
  generateNewResume(data: any, id): Observable<any> {
    const header: HttpHeaders = new HttpHeaders({'Content-Type': 'text/html'});
    return this.http.post(
      `/new/resume/pdf/${id}`,
      data,
      {}
    );
  }
  addResume(data: any): Observable<any> {
    return this.http.post(
      `/resume`,
      data,
      {}
    );
  }
  editResume(data: any): Observable<any> {
    return this.http.patch(
      `/resume`,
      data,
      {}
    );
  }
  getResumeById(id): Observable<any> {
    return this.http.get(
      `/my/resume/${id}`,
      {}
    );
  }
  addCover(data: any): Observable<any> {
    return this.http.post(
      `/cover_letter`,
      data,
      {}
    );
  }
  editCover(data: any): Observable<any> {
    return this.http.patch(
      `/cover_letter`,
      data,
      {}
    );
  }
  getAllCovers(): Observable<any> {
    return this.http.get(
      `/my/cover_letter`,
      {}
    );
  }
  getCoverById(id): Observable<any> {
    return this.http.get(
      `/my/cover_letter/${id}`,
      {}
    );
  }
  resetPassword(data): Observable<any> {
    return this.http.patch(
      `/password/reset`,
      data,
      {}
    )
  };
  emailVerifyAccess(data): Observable<any> {
    return this.http.post(
      `/verify/access`,
      data,
      {}
    )
  };
  searchJob(params?: HttpParams): Observable<any> {
    return this.http.get(
      `/search/job`,
      {params: params}
    );
  };
  // min job req
  getList(params?: HttpParams): Observable<any> {
    return this.http.get(
      `/job`,
      {params: params}
    );
  };
  news_subscribe(data): Observable<any> {
    return this.http.post(
      `/newsletter`,
      data,
      {}
    );
  }
  editProfile(profile): Observable<any> {
    return this.http.patch(
      `/user/profile`,
      profile,
      {}
    );
  }
  processPayment(id): Observable<any> {
    return this.http.post(
      `/payment?id=${id}`,
      {},
      {}
    );
  }
}
