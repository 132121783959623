/**
 * Created by tarun on 6/9/17.
 */
import { Injectable} from '@angular/core';
import {Router} from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import {Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/Operators';

import { LocalStorageService } from 'angular-2-local-storage';
import { AuthService} from '../core/services/auth.service';
import { Config } from '../config/config.config';
import {Token} from '../models/token.model';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(
    private config: Config,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req.url);
    const token: Token = this.localStorageService.get('token') as Token;
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token.token) });
    }

    /*if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }*/
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
    req = req.clone({url: this.config.apiBase + this.config.apiV + req.url});
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === 401) {
          this.authService.logout().then(data => {
            this.router.navigate(['/login']);
          });
        }
        return throwError(err);
      }));
  }
}
